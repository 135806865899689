@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


.Head{
    width: 100%;
    height: 30px; //or 10vh
    position: sticky;
    top:0;
    background-color: white;
    z-index: 1;
   
}


.Logo{
    width: 30%;
    height: auto;
    margin-left: 3%;
    margin-top: 1%;
    font-family: 'Lobster', cursive;
    position: absolute;
    font-size: 27px;


}


/*
.Logo{
    font-family: 'Cookie', cursive;
    display: inline-block;
    width: 40%;
    height: auto;
}

*/