.App {
 
  height: auto;
  width: 100%;
}


.FeedStory{
  pointer-events: none;
}

.Inst::-webkit-scrollbar{
  display: none;
}
  


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}






.AddStory{
  width: 100%;
    border-radius: 50px;
}

.AddIcon{
  position: relative;
    right: 30%;
    background-color: white;
    border-radius: 50px;

}