.Foot{
    width: 100%;
    height: 40px;
    background-color: white;
    bottom: 0;
    position: fixed;
    text-align: center;
    
}

.Icons{
    display: flex;
    justify-content: space-between;
}


.FootReels{
    width: 100%;
    height: 40px;
    background-color: black;
    bottom: 0;
    position: fixed;
    text-align: center;
    align-items: center;
    z-index: 1;
}