.Story{
   // background-color: blue;
    width: 100%;
    height: 110px;
    
   // display: flex;
   //justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
   scrollbar-width: none;
   
}

.TextStory{

   position: absolute;
   display: inline-block;
   top: 55px;
   width: inherit;
   text-align: center;
   white-space: nowrap;
  
   
   
   p{
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.9em;
      font-weight: 500;
   }
}

.Story::-webkit-scrollbar{
 display: none;
}
   
.TriggerNext{
   background-color: black;
    height: 80vh;
    width: 20%;
    position: absolute;
    right: 0;
    z-index: 1;
}
.TriggerPrev{
   background-color: transparent;
    height: 80vh;
    width: 40%;
    position: absolute;
    left: 0;
    z-index: 1;
    
}

.StoryItem{
   width: 65px;
   height: 65px;
    border-radius: 50%;
   
   border: 2px solid #7F7F7F;
   display: inline-block;
   position: relative;
   top:15%;
   margin-left: 5%;
}


.StoryIndex{
   height: 100dvh;
}

.StoryVideoAd{
   height: 100dvh;
}

.StoryScreen{
   height: 100%;
   background-color: black;
   position: relative;
   display: flex;
  
   z-index: 2;
   top: 0;

  
 
  overflow: hidden;
}

.ParentDiv{
   width: 100%;
   height: 100%;
   overflow-x: hidden;
  // transform: translateZ(200px);
   transform: translateZ(175px);
   border: 5px solid black;
   background-color: black;
 
 
}
.ParentDiv2{
   width: 100%;
   height: 100%;
   position: absolute;
   overflow-x: hidden;
   border: 5px solid black;
   top: 0;
   transform: translateX(50%) rotateY(90deg);
   background-color: white;
 
 
}

.ParentDiv3{
   width: 100%;
   height: 100%;
   position: absolute;
   overflow-x: hidden;
   border: 5px solid black;
   top: 0;
   transform: translateX(-50%) rotateY(-90deg);
   background-color: white;
 
}

.ParentDiv4{
   width: 100%;
   height: 100%;
   position: absolute;
   overflow-x: hidden;
   border: 5px solid black;
   top: 0;
   transform: translateZ(-170px) rotateY(0deg);
   background-color: white;
 
}

.Szene{

   height: 100%;
   width: 100%;
   transform-style: preserve-3d;
   -webkit-transform-style: preserve-3d;
   transform: scale(0.85) translateZ(0px);
}



.NextSide{
   position: absolute;
   height: 100%;
    width: 100%;
    transform: rotateY(91deg) translateX(0px);
  margin-left: 60%;
  
   background-color: green;
}


/*
.css-m9glnp-MuiPaper-root-MuiDialog-paper{
   //background-color: transparent;
   perspective: 1000px;
   perspective-origin: center;
   transform-style: preserve-3d;
  // transform: rotate(50deg);
  
   overflow-y: hidden !important;
   overflow-x: hidden !important;
   

}
*/

#storyvideoad{
   pointer-events: none;
}

.css-1hyl1h2{
   perspective: 1000px;
   perspective-origin: center;
   transform-style: preserve-3d;
  // transform: rotate(50deg);
   overflow: hidden;
   overflow-y: hidden;
   -webkit-perspective: 1000px;
   -webkit-perspective-origin: center;
}



.Dialog{
  //perspective: 400px;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: hidden;
 
}

#ad-layer{
   width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  
}


.StoryIcon{
   width: 100%;
   height: 30px;
   display: flex;
   align-items: center;

    position: absolute;
    left:3%;
    top:25%;
  
    z-index: 0;
}

.incheck_head-text{
   color: whitesmoke;
    position: absolute;
    left: 50px;
    font-size: 13px;
    font-weight: 500;
    text-shadow: 0px 0px 4px black;
}


#storyiconpicVid{
   width: 100%;
   height: 100%;
   position: absolute;
   border-radius: 50px;
   background-color: white;
}


.Message{
   border: 1px solid whitesmoke;
   width: 65%;
   height: 35px;
   border-radius: 40px;

   color: whitesmoke;
   display: flex;
   align-items: center;

}




.story_slide{
   height: 90vh;
}

.Message_Icon{
   width: 100%;
   height: 10%;  //8%
   display: flex;
    align-items: center;
    justify-content: space-around;
   
    
   position: relative;
   
 
}

.Main{
   -webkit-transform-style: preserve-3d;
   transform-style: preserve-3d;
  // perspective: 400px;
  // -webkit-perspective: 400px;
   background-color: white;
   //display: none;
   display: block;
   overflow-x: hidden;
   
}

.BlackDiv{
   background-color: black;
   width:100%;
   height:100%;
    position:absolute;
     top:0;
   z-index: -2;
 }

 .VideoAd{
   width: 100%;
   height: 100vh;
   
   
 }

 .Off{
   display: none;
 }


 .StoryContent{
   //  background-color: black;
     width: 100%;
     height: 90%; //90%
    position: relative;
   
    // animation: test 3s infinite linear;
    
  }
  
  
  .StoryImage{
     width: 100%;
     height: 100%; //75%%
     position: absolute;
     top:0;
   pointer-events: none;
   object-fit: cover;
    
  }
  





  
   
   

/*
@keyframes rotate {
   
  0%{transform: rotateY(0deg);}
   100% { transform: rotateY(-90deg); }

}
*/


.StoryIconPic{
   width: 30px;
   height: 100%;
   position: absolute;
   object-fit: cover;
   border-radius: 50px;
   object-position: 50% 0%;
}
.StoryIconPicItem{
   width: 100%;
   height: 100%;
   position: absolute;
   border-radius: 50px;
   object-fit: cover;
   object-position: 50% 0;
}

.Swipe{
   height: 75%;
   width: 30%;
   background-color: green;
   position: absolute;
   z-index: 1;
   right: 0;
}


.StoryLoader{
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 3;
}

.TimeLine{
   width: 100%;
   height: 5px;
   background-color: #7F7F7F;
   border-radius: 50px;
   //position: absolute;
}

.TimeRemains{
   background-color: whitesmoke;
   height: 5px;
   transition: all 0.5s;
   //width: 50%;
}


.VideooAd{
   width: 100%;
    height: auto;
    position: absolute;
    top: 0;
}


.story-clickTrigger-prev{

   width: 30%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   z-index: 2;
}


.story-clickTrigger-next{


   width: 30%;
   height: 100%;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 2;
}


// Dialog

.story-playContent-dialog{
   height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    background: white;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .story-playContent_btn-container{
  
   width: 130px;
   height: 50px;
 
 }
 
 .story-playContent_btn-text{
   
   font-size: 25px;
   font-weight: 600;
   position: absolute;
   top: 150px;
 }


 .story-playContent_btn{
   width: 100%;
   height: 100%;
   font-size: 19px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   font-weight: 600;
   background: black;
   border-radius: 6px;
  
 }