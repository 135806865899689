.Main{
    
    width: 100%;
    min-height: 100vh;
}

.DivNone{
    display: none;
}

.Post{
   // background-color: blue;
    height: 100%;
    width: 100%;
    padding-bottom: 10%;
}

.Post_Carousel{

   
}

.carousel-buttons-foot{
    margin-top: 5px;
}

.feed-carousel-slide-item{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.incheck-carousel-count{
position: absolute;
  z-index: 2;
  background: #262626;
  color: white;
  width: 50px;
  height: 30px;
  right: 10px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}


.feed-carousel-slide{
    
    width: 100%;
    position: relative;
}

.feed-carousel-slide-AdText{
    display: flex;
    position: absolute;
    color: white;
    width: 225px;
    font-size: 20px;
    font-weight: bold;
    top: 10%;
    left: 5%;
    transition: 0.5s all;
    z-index: 2;
    opacity: 1;
}

.AdText2{
    opacity: 1;

}

.audia1class{
    background: white;
    color: black;
    width: 100%;
    font-size: 18px;
    left: 0;
    padding: 5px 0px 5px 10px;
    top: 88%;
    display: flex;

}

.eautoclass{
    background: white;
    color: black;
    width: 100%;
    font-size: 18px;
    left: 0;
    padding: 5px 0px 5px 10px;
    top: 82%;
    display: flex;

}

.fewoclass{
    background: white;
    color: black;
    width: fit-content;
    font-size: 18px;
    left: 0;
    padding: 0 10px 0 10px;
    color: #000075;
   
}

.tuiclass{
    top: 9%;
    font-size: 18px;
    left: 0;
    padding: 5px 0px 5px 10px;
    background: white;
    color: black;
    width: 100%;
    
}

.feed-carousel-slide-AdCTA-container{
   // position: absolute;
    opacity: 1;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    bottom: 7%;
}

.feed-hpa-slide-AdCTA-container{
    position: relative;
    z-index: 2;
    opacity: 1;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
  
}

.feed-carousel-slide-AdCTA{
    display: flex;
    position: absolute;
    background: white;
    bottom: 0%;
    height: inherit;
    font-size: 14px;
    width: 100%;
    font-weight: bold;
    justify-content: start;
    align-items: center;
    z-index: 2;
    transition: 0.5s all;
    opacity: 1;
    color: black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    

}


.feed-carousel-slide-linkBtn{
    position: absolute;
    right: 10px;
  
    z-index: 2;
}

.feed-carousel-slide-AdCTAActive{
    animation: ctaActive 3s linear infinite 1s;
}

@keyframes ctaActive {
   
    0%{transform: scale(1);}
    50%{transform: scale(1.1);}
    100%{transform: scale(1);}
   
}

/*
#incheck-carousel::part(pagination) {

 
  position: absolute;
  top: 90%;
    
     
    }

    */





.Pic{
    padding-top: 2%;
    width: 100%;
    height: 70%;
    
}

.loaderIconNone{
    display: none;
}

.loaderIcon{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.muteIcon{
    width: 20px;
    height: 20px;
    background-color: #646464;
    border-radius: 50px;
    bottom: 10px;
    position: absolute;
    right: 10px;
    text-align: center;
}

.muteIconNone{
  display: none;
}

.MinPic{
    //left: 3%;
    //position: absolute;
    
    width: 30px;
    height: 30px;
   
}

#fewologo{
   
  width: 40px;
  height: 40px;
}
#samsunglogo{
   
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

#tuilogo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
#audia1logo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

#eautologo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

#ergologo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #e7e7e7;
}

.Icon_Name{
    width: 100%;
  height: 7%;
  align-items: center;
  display: flex;

}
.carousel-Icon_Name{

    width: 100%;
    height: 7%;
    //border: 2px solid red;
    align-items: center;
    display: flex;

    .carousel-Icon_Name-Name{
        position: relative;
       // left: 15%;
      // left: 50px;
        font-weight: bold;
      
    }
}

.carousel-Icon_Name-Name{
    position: relative;
    margin-left: 10px;
     font-weight: bold;
     font-size: 14px;
   
}

.minPic-container{
    height: 100%;
    width: fit-content;
  
    margin-left: 8px;
    
}

.PostText{
    width: 90%;
    margin-left: 3%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
    //font-size: smaller;
    font-size: 4vw;
    margin-block-end: 5px;
   // padding: 5px 0px 5px 0px;

   span{
    padding-right: 5px;
   }
}

.fewoclassStaticText{
    opacity: 1;
    background: white;
    color: black;
    width: 100%;
    font-size: 18px;
    left: 0;
    
    color: #000075;
    padding: 5px 0px 5px 10px;
}

.incheck-carousel_container{

    height: auto; //560
    position: relative;
}
.incheck-hpa_container{

    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
}

swiper-container::part(wrapper) {
    height: 85%;
  }
  
swiper-container::part(pagination) {
    position: absolute;
    bottom: 2%;
    z-index: 4;
  }
  

  .carousel-Icon_Name-ad_label{
    margin: 5px 0 0 10px;
    font-size: 11px;
    
  }
  
  .post-text_container{
    position: relative;
  }

  .post-text_more{
    margin-left: 20px;
    font-size: 15px;
    color: gray;
    
  }