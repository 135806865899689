.Story{
   // background-color: blue;
    width: 100%;
    height: 110px;
    overflow-y: hidden;
   // display: flex;
   //justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
   scrollbar-width: none;
   background: white;
   
}


.StoryScreen{
 
   height: 100%;
   background-color: black;
   position: relative;
   display: flex;
  
   z-index: 2;
   top: 0;


 
  overflow: hidden;
   /*
   scroll-snap-type: x mandatory;
   scroll-snap-stop: always;
   white-space: nowrap;
   overflow-x: scroll;
   */
}



.StorySrcreenIndex{
   display: flex;
}

.swiper-cube{
  
  
}


.ParentDivDemo{
   width: 100%;
   height: 100%;

//visibility: visible;

/*
scroll-snap-align: start;
scroll-snap-stop: always;
   background-color: black;
 */
 
}


.TextStory{

   position: absolute;
   display: inline-block;
   top: 55px;
   width: inherit;
   text-align: center;
   white-space: nowrap;
  
   
   
   p{
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.9em;
      font-weight: 500;
   }
}

.Story::-webkit-scrollbar{
 display: none;
}
   
.TriggerNext{
   background-color: black;
    height: 80vh;
    width: 20%;
    position: absolute;
    right: 0;
    z-index: 1;
}
.TriggerPrev{
   background-color: transparent;
    height: 80vh;
    width: 40%;
    position: absolute;
    left: 0;
    z-index: 1;
    
}

.StoryItemDemo{

   width: 65px;
   height: 65px;
    border-radius: 50%;
   
   border: 2px solid #7F7F7F;
   display: inline-block;
   position: relative;
   top:15%;
   margin-left: 5%;
}




.NextSide{
   position: absolute;
   height: 100%;
    width: 100%;
    transform: rotateY(91deg) translateX(0px);
  margin-left: 60%;
  
   background-color: green;
}



.css-m9glnp-MuiPaper-root-MuiDialog-paper{
 
  
   overflow-y: hidden !important;

   

}




.css-1hyl1h2{
   perspective: 1000px;
   perspective-origin: center;
   transform-style: preserve-3d;
  // transform: rotate(50deg);
   overflow: hidden;
   overflow-y: hidden;
   -webkit-perspective: 1000px;
   -webkit-perspective-origin: center;
}



.Dialog{
  //perspective: 400px;
  overflow-x: hidden;
  overflow-y: hidden;
 
}

.closeButtonImg{
   right: 2%;
  position: absolute;
  color: rgb(134, 134, 134);

  z-index: 1;
  width: 6%;
  height: auto;

  img{
   width: 100%;
  }
}


.StoryIcon{
   width: 100%;
   height: 30px;
  

    position: absolute;
    left:3%;
    top:25%;
  
    z-index: 0;
}



.Message{
   border: 1px solid whitesmoke;
   width: 65%;
   height: 75%;
   border-radius: 40px;
   color: whitesmoke;
   display: flex;
   align-items: center;
}

.Message_Icon{
   width: 100%;
   height: 8%;
  
   position: relative;
   
 
}

.Main{
   scroll-snap-type: y mandatory;
  
   background-color: white;
   //display: none;
   display: block;
   overflow-y: hidden;
}

.BlackDiv{
   background-color: black;
   width:100%;
   height:100%;
    position:absolute;
     top:0;
   z-index: -2;
 
 }

 .VideoAd{
   width: 100%;
   height: 100%;
   
   
 }

 .Off{
   display: none;
 }

.StoryContent{
 //  background-color: black;
   width: 100%;
   height: 100%;
  position: relative;
 
  // animation: test 3s infinite linear;
  
}


.StoryImage{
   width: 100%;
   height: 100%; //75%%
   position: absolute;
   top:0;
 pointer-events: none;
  
}

.StoryVideo{
   width: 100%;
   height: 100%; //75%%
   position: absolute;
   top:0;
 pointer-events: none;
 object-fit: cover;
  
}





  
   
   

/*
@keyframes rotate {
   
  0%{transform: rotateY(0deg);}
   100% { transform: rotateY(-90deg); }

}
*/


.StoryIconPic{
   width: 30px;
   height: 100%;
   position: absolute;
   border-radius: 50px;
}

.StoryIconPicItem{
   width: 100%;
   height: 100%;
   position: absolute;
   border-radius: 50px;
}

.Swipe{
   height: 75%;
   width: 30%;
   background-color: green;
   position: absolute;
   z-index: 1;
   right: 0;
}

.TimeLine{
   width: 100%;
   height: 5px;
   background-color: #7F7F7F;
   border-radius: 50px;
   //position: absolute;
}

.TimeRemains{
   background-color: whitesmoke;
   height: 5px;
   transition: all 0.25s;
   //width: 50%;
}


.story-clickTrigger-prev{

   width: 30%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   z-index: 2;
}


.story-clickTrigger-next{


   width: 30%;
   height: 100%;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 2;
}

