@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


.Head{
    width: 100%;
    height: 45px; //or 10vh
    position: sticky;
    top:0;
    background-color: white;

   
    z-index: 3;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo_name_container{
    height: 100%;
  width: 115px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 23px;
  font-weight: 700;
  margin-left: 15px;
  img{
    width: 100%;
  }
}

.head_icons_container{
    display: flex;
}

.Logo{
    width: 30%;
    height: auto;
    margin-left: 3%;
    margin-top: 1%;
    font-family: 'Lobster', cursive;
    position: absolute;
    font-size: 27px;


}


/*
.Logo{
    font-family: 'Cookie', cursive;
    display: inline-block;
    width: 40%;
    height: auto;
}

*/