@import url('https://fonts.googleapis.com/css2?family=Anek+Odia:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.MainBody{
   // border: 2px solid black;
     min-height: 100vh;
     
     
 
 }

 .Impressum{
     height: 100vh;
     width: 100%;
     background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
   

    .p3{
        position: absolute;
       font-family: 'Anek Odia', sans-serif;
       left:50%;
        top:90%;
        transform: translate(-50%, 0%);
       font-size: 1.2vw;
       font-weight: bolder; 

       a{
        text-decoration: none;
        color:#2ba6cb
    }
   
    a:hover{
     color:#176780
 }
    }


    .foot{
       
       font-family: 'Anek Odia', sans-serif;
      position: absolute;
       bottom: 0;
       text-align: center;
      
       font-size: 10px;
       font-weight: bolder; 

       a{
        text-decoration: none;
        color:#2ba6cb
    }
   
    a:hover{
     color:#176780
 }
    }
 }


 .MaintainDiv{
    
    width: 100%;
    height: 100vh;
    display: flex;
  align-items: center;
 }

 .Maintaintext{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 8vw;
 }
 .MaintainLogoText{
    width: 100%;
   position: absolute;
   top: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 8vw;
    font-family: 'Lobster', cursive;
 }
 
.Text{
   
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.p{
   
   font-family: 'Anek Odia', sans-serif;
    font-size: 8vw;
    font-weight: bold;
}

.p2{
  
  font-family: 'Anek Odia', sans-serif;
  font-size: 5vw;
  margin-top: 20px;
  font-weight: bolder;
  

  a{
      text-decoration: none;
      color:#2ba6cb
  }
 
  a:hover{
   color:#176780
}
}


