.Screen{
    width: 100%;
    height: 100vh;
    background-color: black;
   position: relative;
   overflow-x: hidden;
   //overflow-y: hidden;
  
   z-index: 2;
   top:0;
   
   display: flex;
   justify-content: space-between;
    flex-direction: column;
   // overflow-y: scroll;
   // overflow-x: none;
   
    white-space: nowrap;
    z-index: 0;
    scroll-snap-type: y mandatory;
    
}

.Mainios{
    width: 100%;
    height: 100vh;
    scroll-snap-type: y mandatory;
    
    
}

.ReelBody{
 
   
}

.PlayIcon{
    position: sticky;
    width: 20%;
    background-color: black;
    border-radius: 50%;
    opacity: 0.8;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    transition: 0.5s all;
    z-index: 1;
    display: none;
}


.ReelsIcons-Counts{
    text-align: center;
    color: white;
    font-size: 12px;
  line-height: 20px;
    padding-bottom: 10px;
}


.Reel{
    
    width: 100%;
  height: 100%;
 scroll-snap-align: start;
 scroll-snap-stop: always;

}

.ReelImg{
    width: 100%;
    height: 100%;
   // z-index: 1;
   // transform: translateY(150px);
  // transition: 1s all;
 //display: block;
 margin-bottom: 5%;
 scroll-snap-align: start;
 scroll-snap-stop: always;
}

.Off{
 display: none;
 
 justify-content: center;
 z-index: 1;
 height: 100vh;
 align-items: center;
 position: absolute;
 width: 100%;
}



.Reel2{
    width: 100%;
    height: 100%;
    transform: scale(1.5);
    z-index: -1;
    position: absolute;
    //transition: 1s all;
    filter: blur(5px);
    
}


.ReelsLoader{
    z-index: 4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
}




.Window{
    border: 2px solid red;
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 3;
    background-color: white;
  
  
}

.ClickBtn{
    border: 2px solid black;
width: 30%;
position: absolute;
left: 30%;
top: 30%;
cursor: pointer;
}


// IOS Dialog

.IosDialog{
    background-color: white;
    position: absolute;
    height: 100%;
    z-index: 5;
    width: 100%;
    top: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.IosText{

    font-size: 21px;
    font-weight: bold;
    padding-bottom: 50px;

}

.IosBtn{
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 120px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;

}

//ReelsIcons

.ReelsIcons{
    width: 40px;
    height: 40%;
    position: absolute;
    right: 10px;

    bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ReelsIconsTop{
    width: 100%;
    height: 10%;
    position: absolute;
    top: 0;
    
    display: flex;
    color: white;
    font-size: 22px;
    font-weight: bold;
    justify-content: space-between;
    align-items: end;


    #ReelsText{
        margin-left: 20px;
    }
}

.Ad{
    justify-content: end;
}

.ReelsDescr{
    //border: 2px solid blue;
    bottom: 5px;
    height: 10%;
    position: absolute;
    color: white;
    display: flex;

    font-weight: 500;


    .ReelsDescr-Container{
        display: flex;
        
        height: fit-content;
        align-items: center;
    }

    .ReelsDescr-AccImg{
        width: 30px;
        height: 30px;
        border-radius: 50%;
     
        margin-left: 10px;

        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .ReelsDescr-AccName{
        margin-left: 10px;
        font-size: 14px;
    }

    .ReelsDescr-SubscrBtn{
        border: 1px solid white;
        height: fit-content;
        margin-left: 20px;
        padding: 3px 3px 3px 3px;
        font-size: 13px;    
        border-radius: 5px;

    }
    
}

