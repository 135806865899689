.Screen{
    width: 100%;
    height: 100dvh;
    background-color: black;
   position: relative;
   overflow-x: hidden;
   //overflow-y: hidden;
   //position: absolute;
   z-index: 2;
   top:0;
   //pointer-events: none;
   display: flex;
   justify-content: space-between;
    flex-direction: column;
   // overflow-y: scroll;
   // overflow-x: none;
   
    white-space: nowrap;
    z-index: 0;
    scroll-snap-type: y mandatory;
    
}


.reels_Main{
    width: 100%;
    height: 100dvh;
    overflow-y: hidden;
}

.ReelBody{
    height: 100dvh;
    position: relative;
  
   
}

.PlayIcon{
    position: sticky;
    width: 20%;
    background-color: black;
    border-radius: 50%;
    opacity: 0.8;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    transition: 0.5s all;
    z-index: 1;
    display: none;
}

.reels-video_container{
    height: 100dvh;
    width: 100%;
}

.Reel{
    
    width: 100%;
   height: 100%;
object-fit: cover;

}

.ReelImg{
    width: 100%;
    height: 100%;
   // z-index: 1;
   // transform: translateY(150px);
  // transition: 1s all;
 //display: block;
 margin-bottom: 5%;
 scroll-snap-align: start;
 scroll-snap-stop: always;
}

.Off{
 display: none;
 
 
}



.Reel2{
    width: 100%;
    height: 100%;
    transform: scale(1.5);
    z-index: -1;
    position: absolute;
    //transition: 1s all;
    filter: blur(5px);
}


.ReelsLoader{
    display: flex;
    justify-content: center;
    top: 40%;
    position: absolute;
    z-index: 3;
}



//ReelsIcons

.ReelsIcons{
    width: 40px;
    height: 40%;
    position: absolute;
    right: 10px;

    bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ReelsIconsTop{
    width: 100%;
    height: 10%;
    position: absolute;
    top: 0;
    
    display: flex;
    color: white;
    font-size: 22px;
    font-weight: bold;
    justify-content: space-between;
    align-items: end;


    #ReelsText{
        margin-left: 20px;
    }
}

.Ad{
    justify-content: end;
}

.ReelsDescr{
    //border: 2px solid blue;
    bottom: 6%;
    height: 10%;
    position: absolute;
    color: white;
    display: flex;

    font-weight: 500;


    .ReelsDescr-Container{
        display: flex;
        
        height: fit-content;
        align-items: center;
    }

    .ReelsDescr-AccImg{
        width: 30px;
        height: 30px;
        border-radius: 50%;
     
        margin-left: 10px;

        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .ReelsDescr-AccName{
        margin-left: 10px;
        font-size: 14px;
    }

    .ReelsDescr-SubscrBtn{
        border: 1px solid white;
        height: fit-content;
        margin-left: 20px;
        padding: 3px 3px 3px 3px;
        font-size: 13px;    
        border-radius: 5px;

    }
    
}